.baaner {
  margin-top: 0rem;
}
.home-about-section {
  height: 70vh;
  width: auto;
}
.home-about-main {
  /* border: 2px solid red; */
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-image{
    margin: 2rem 0;
    /* height: ; */
}
.about-text{
    margin: 2rem 0;
}
.slider-class{
  margin-top: -15rem;
}


@media only screen and (max-width: 990px) {
  .slider-class{
    margin-top: -3rem;
    margin-bottom: -8rem;
  }
  .our-treatment{
  }
  .baaner {
    margin-top: 0rem;
  }
  .home-about-section {
    height: auto;
    width: auto;
    padding: 2rem;
  }
  .home-about-main {
    width: auto;
    height: auto;
    display: flex;
    /* margin: 0; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .home-about-image {
    margin-right: 5rem;
    width: 80%;
  }
  .second-div {
    /* border: 2px solid red; */
    /* margin-left: -6rem; */
  }
  .about-main {
    display: flex;
    flex-direction: column;
  }
  .about-image {
    /* border: 2px solid red; */
    margin: 2rem 0;
  }
}
