.nv {
    height: 6rem;
    width: 100%;
}
@media only screen and (max-width: 600px) {
    .nv{
        margin-bottom: 4rem;
    }
}



@media only screen and (min-width: 600px) and (max-width: 990px){
    .nv{
        margin-bottom: 7rem;
    }
}

@media only screen and (max-width: 990px) {
    .nv {
        height: 15rem;
        /* margin-bottom: 7rem; */
    }
}

.home {
    background-color: white;
    margin-top: 4rem;
}

@media only screen and (max-width: 990px) {
    .home {
        display: none;

    }
}


.principal {
    background-color: white;
    margin-left: 10rem;
    color: #3694C8;
    width: 50% auto ;
    text-align: left;
    /* margin: 1rem; */
}

.principal1 {
    background-color: white;
    margin-top: 0rem;
    color: #3694C8;
    width: 50%;


}


@media only screen and (max-width: 990px) {
    .principal {
        width: 100%;
        text-align: center;
        margin-left: -0rem;
        margin-top: 0rem;
        color: #3694C8;
    }

    .principal1 {
        width: 100%;
        text-align: center;
        margin-top: 0rem;
        font-size: x-large;
    }
    .header-section{
        flex-direction: column;
    }
}

.dropdown-menu {

    z-index: 9999;
}

@media only screen and (min-width: 990px) and (max-width: 1200px){
.new-navv{
    margin-top: -5rem;
}

}


@media only screen and (min-width: 1200px) and  (max-width: 1600px){
    .new-navv{
        margin-top: -3rem;
    }
}

#carousel-image{
 border: "10px solid red";
}