.container-fluid {
    height: 3rem;
    width: 100%;
}

@media only screen and (max-width: 2000px) {
    .container-fluid {
        background-color: #3694C8;
        height: auto;
        width: 100%;
        margin-top: 0rem;

    }

    .text {
        margin-top: 0rem;
        padding: -10rem;
        margin-left: 2rem;

    }

    .text li {
        margin-top: 0rem;
        padding: -10rem;
        margin-left: 2rem;

    }

    .text a {
        margin-top: 0rem;
        padding: -10rem;
        margin-left: 2rem;

    }

}



@media only screen and (max-width: 1300px) {
    .container-fluid {
        background-color: #3694C8;
        height: auto;
        width: 100%;
        margin-top: 0rem;

    }

    .text {
        margin-top: 0rem;
        padding: -10rem;
        margin-left: 0rem;

    }

    .text li {
        margin-top: 0rem;
        padding: -10rem;
        margin-left: 0rem;

    }

    .text a {
        margin-top: 0rem;
        padding: -10rem;
        margin-left: 0rem;

    }

}

@media only screen and (max-width: 800px) {
    .container-fluid {
        background-color: #3694C8;
        height: auto;
        width: 100%;
        margin-top: 0rem;

    }
}


.photo {
    height: auto;
    margin-top: 10rem;
}

@media only screen and (max-width: 990px) {
    .photo {
        height: 60px;
        margin-top: 0rem;
    }

    .container-fluid {
        background-color: #3694C8;
        height: auto;
        width: 100%;
        margin-top: 0rem;
        padding: 2rem;
    }

}




.house:hover {

    text-decoration: none;
}

@media only screen and (max-width: 990px) {
    .house {

        text-decoration: none;
    }
}


.house:hover {

    text-decoration: none;
}

@media only screen and (max-width: 990px) {
    .house {

        text-decoration: none;
    }
}


.about:hover {

    text-decoration: none;
}

@media only screen and (max-width: 990px) {
    .about {

        text-decoration: none;
    }
}


.desk:hover {

    text-decoration: none;
}

@media only screen and (max-width: 990px) {
    .desk {

        text-decoration: none;
    }
}


.circ:hover {

    text-decoration: none;
}

@media only screen and (max-width: 990px) {
    .circ {

        text-decoration: none;
    }
}


.pta:hover {

    text-decoration: none;
}

@media only screen and (max-width: 990px) {
    .pta {

        text-decoration: none;
    }
}


.admi:hover {

    text-decoration: none;
}

@media only screen and (max-width: 990px) {
    .admi {

        text-decoration: none;
    }
}

.hall:hover {

    text-decoration: none;
}

@media only screen and (max-width: 990px) {
    .hall {

        text-decoration: none;
    }
}





@media only screen and (max-width: 990px) {
    .text {
        margin-top: 0rem;

    }
}

@media only screen and (max-width: 990px) {
    .button {
        width: 100%;
        text-align: right;
    }
}